.tables {
  width: 900px;
  border: 2px solid black;
  margin: 20px auto;
}
tr th {
  font-size: 23px;
}
@media (max-width: 930px) {
  .tables {
    width: 700px;
  }
}
@media (max-width: 722px) {
  .tables {
    width: 600px;
  }
}
@media (max-width: 621px) {
  .tables {
    width: 500px;
  }
}
@media (max-width: 530px) {
  .tables {
    width: 400px;
  }
}
