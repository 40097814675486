#container {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
  border-radius: 10px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../assets/p404.png), #151729;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
}
#container .content {
  max-width: 600px;
  text-align: center;
}
#container .content h2 {
  font-size: 10vw;
  color: #c8d6e5;
  margin-top: 30px;
  line-height: 1rem;
}
#container .content h4 {
  position: relative;
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-top: 109px;
  color: #111;
  background: #fff;
  font-weight: 300;
  padding: 10px 20px;
  display: inline-block;
}
#container .content p {
  color: #fff;
  font-size: 1.2rem;
}
