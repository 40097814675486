@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form {
  border: 5px solid black;
  border-radius: 20px;
  box-shadow: 5px 5px 10px gray;
  padding: 30px;
  margin: 30px;
}

.tables {
  width: 900px;
  border: 2px solid black;
  margin: 20px auto;
}
tr th {
  font-size: 23px;
}
@media (max-width: 930px) {
  .tables {
    width: 700px;
  }
}
@media (max-width: 722px) {
  .tables {
    width: 600px;
  }
}
@media (max-width: 621px) {
  .tables {
    width: 500px;
  }
}
@media (max-width: 530px) {
  .tables {
    width: 400px;
  }
}

.parent {
  border: 5px solid black;
  border-radius: 20px;
  box-shadow: 5px 5px 10px gray;
  margin: 50px;
  margin-top: 10vh;
  padding: 20px;
}
.order-confirm {
  display: flex;
}
.place-order {
  position: absolute;
  right: 25px;
}
.confirm-img {
  margin-right: 10px;
  border-radius: 20px;
  width: 290px;
}
.confirm-text {
  padding: 20px;
  color: white;
  border-radius: 20px;
  background-color: #222f3e;
}
@media (max-width: 777px) {
  .confirm-img {
    margin-bottom: 25px;
  }
  .order-confirm {
    display: block;
  }
}

.product-card {
  padding: 20px;
  margin: auto;
  float: none;
  margin-bottom: 20px;
  margin-top: 10px;
  box-shadow: 5px 5px 10px lightgrey;
}
.buy-now-btn {
  background-color: #10ac84;
  margin-top: 10px;
}
.spinner {
  width: 50vw;
}

.login {
  text-align: center;
}

.login-box {
  border: 5px solid #222f3e;
  background-color: #576574;
  margin: 5px 30%;
  border-radius: 10px;
}
.login-btn {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid gray;
  background-color: white;
  margin-bottom: 10px;
}

.login-btn img {
  margin-right: 50px;
}

#container {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
  border-radius: 10px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/static/media/p404.9b65855e.png), #151729;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
}
#container .content {
  max-width: 600px;
  text-align: center;
}
#container .content h2 {
  font-size: 10vw;
  color: #c8d6e5;
  margin-top: 30px;
  line-height: 1rem;
}
#container .content h4 {
  position: relative;
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-top: 109px;
  color: #111;
  background: #fff;
  font-weight: 300;
  padding: 10px 20px;
  display: inline-block;
}
#container .content p {
  color: #fff;
  font-size: 1.2rem;
}

.orders {
  border: 2px solid gray;
  box-shadow: 5px 5px 10px darkgray;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
}

